/**
* Used to show "Made with Mailchimp" at the bottom of signup forms.
* This was formerly known as monkey rewards.
**/
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/44bbfd114dce4a8547b38259158b61b11c1b1512/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dojo/text!./templates/textBadge.html", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/44bbfd114dce4a8547b38259158b61b11c1b1512/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, _WidgetBase, _TemplatedMixin, template, domAttr) {
  return declare([_WidgetBase, _TemplatedMixin], {
    templateString: template,
    rewardsUrl: "mailchimp.com",
    postCreate: function () {
      this.inherited(arguments);
      //update the url with what is passed in
      domAttr.set(this.monkeyRewardsLink, "href", this.rewardsUrl);
    }
  });
});