define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/44bbfd114dce4a8547b38259158b61b11c1b1512/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/validation/Error", "mojo/lib/ServiceUnavailableError"], function (declare, ValidationError, ServiceUnavailableError) {
  var Helpers = {
    /**
     * Based on the response code, type the response to an error class
     *
     * @param {object} response From handler for failed xhr request
     *
     * @returns {Error} Appropriately typed Error object
     */
    transformErrorFromResponse: function (response) {
      var status = response.response.status;
      var errorClass = Helpers._getErrorClassForStatus(status);
      if (errorClass === null) {
        return new Error();
      }
      if ("createFromResponseData" in errorClass === false) {
        throw new TypeError("errorClass for status '" + status + "' must implement createFromResponseData()");
      }
      var errorInstance = errorClass.createFromResponseData(response.response.data);
      return errorInstance;
    },
    /**
     * Get the class matching the given status code.
     *
     * @param {integer} code - The HTTP status code of the network response
     *
     * @returns {*} Javascript class
     *
     * @private
     */
    _getErrorClassForStatus: function (code) {
      if (code === 400) {
        return ValidationError;
      }
      if (code === 503) {
        return ServiceUnavailableError;
      }
      return null;
    },
    /**
     * Throw the typed error response for these endpoints, throwing a mojo/validation/Error where appropriate
     *
     * @param {object} response The first argument passed to the handler for a failed xhr request
     *
     * @throws {Error} An error object appropriately representing the validation error or general error
     */
    throwError: function (response) {
      throw Helpers.transformErrorFromResponse(response);
    }
  };
  return Helpers;
});